<template>
  <div class="productDetails-container">
      <div class="productImg">
        <div class="header2">
            <van-nav-bar title="产品基础信息" left-text="返回列表"  left-arrow @click-left="onClickLeft"></van-nav-bar>
        </div>
        <div class="title_product">
            {{productList.commodityName}}
        </div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in productList.commodityImgs" :key="index">
                <van-image
                    width="100%"
                    height="100%"
                    @click="showImg"
                    :src="item.url"
                    />
            </van-swipe-item>
        </van-swipe>
      </div>
      <div class="productDescribe">
          <van-cell-group>
            <van-cell title="商品大类:" class="myCell" :value="productList.cctn" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="细分品类:" class="myCell" :value="productList.commodityContractTypeName" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="所属品牌:" class="myCell" :value="productList.name" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="商品特点:" class="myCell" :value="productList.description" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="商品证书:" class="myCell swipe_cs" value="productList.">
                <van-swipe :loop="false" :show-indicators=false> 
                    <van-swipe-item v-for="(item, index) in productList.commodityCertificates" :key="index">
                        <van-image
                            width="70"
                            height="70"
                            @click="showImg"
                            :src="item.url"
                            />
                    </van-swipe-item>
                </van-swipe>
            </van-cell>
           </van-cell-group>

          <van-cell-group>
            <van-cell title="质检报告:" class="myCell swipe_cs" value="productList.">
                <van-swipe :loop="false" :show-indicators=false> 
                    <van-swipe-item v-for="(item,index) in productList.inspectionReports" :key="index">
                        <van-image
                            width="70"
                            height="70"
                            v-if="item.url.indexOf('.pdf') == -1"
                            @click="showImg"
                            :src="item.url"
                            />
                            <a :href="item.url" target="_black" v-else style="padding: 4px 8px; font-size: 12px; background-color: #e69329; border-radius: 50px; display: inline-block; color: white;">查看PDF</a>
                    </van-swipe-item>
                </van-swipe>
            </van-cell>
           </van-cell-group>

          <van-cell-group>
            <van-cell title="审核状态:" class="myCell" :value="productList.statusName" style="margin-bottom: 100px;">
                <template #default>
                    <span style="color: red;" v-if="flags">{{productList.statusName||'-'}}</span>
                    <span v-else>{{productList.statusName || '-'}}</span>
                </template>
            </van-cell>
           </van-cell-group>
      </div>
      <div style="color: #133676; font-size: 14px; position: fixed; top: 14px; right: 20px; z-index: 1000" v-if="productList.showEditBtn">
            <van-popover v-model="showPopover" trigger="click" :actions="actions" @select="onSelect" placement="bottom-end" >
                <template #reference>
                    <van-button class="showBtn"></van-button>
                </template>
            </van-popover>
      </div>
  </div>
</template>
<script>
import {ImagePreview} from 'vant'
import { Popover } from 'vant';
import { Dialog } from 'vant';
import { Toast } from 'vant';
import { Empty, Notify } from 'vant'
export default {
    data(){
        return{
            flags: true,
            showPopover: false,
            actions: [
                { text: '编辑产品', icon: 'edit' },
                { text: '删除产品', icon: 'delete-o' },
            ],
            imgList:[],
            productList: [],
        }
    },
    created(){
        Toast.loading({
            message: '加载中...',
            overlay: true,
            duration: 300,
            forbidClick: true,
        });
        setTimeout(()=>{
            this.getProductList();
        }, 100)
    },
    methods:{
        onSelect(action) {
            if(action.text == '编辑产品'){
                this.compileProduct();
            }else{
                this.deleteProduct();
            }
        },

        // 跳转编辑产品
        compileProduct(){
            this.$router.push({
                path: '/compileProduct',
                query:{
                    id: this.$route.query.id
                }
            })
        },
        deleteProduct(){
            Dialog.confirm({
                title: '删除产品',
                message: '您确定要删除当前产品吗???',
            })
            .then(() => {
                this.$http.ajax('/ffkj-main/merchantCommodity/del',{
                    merchantId: localStorage.getItem('merchantId'),
                    commodityId: this.$route.query.id
                },'get',{}).then(res=>{
                    if( res.code == 0 ){
                        Notify({ type: 'success', message: '删除成功'})
                        setTimeout(()=>{
                            this.onClickLeft();
                        }, 400)
                    }else{
                        Notify({ type: 'danger', message: res.message })
                    }
                })
            })
            .catch(() => {
                
            });
        },
        showImg(e){
            this.imgList.push(e.target.src)
            ImagePreview(this.imgList)
            this.imgList = [];
        },
        // 返回列表
        onClickLeft(){
            this.$router.push({
                path: '/product',
                query: {
                    active: 1
                }
             })
        },
        getProductList(){
            this.$http
            .ajax(
            '/ffkj-main/merchantCommodity/page',
            {
                pageNum: 1,
                pageSize: 5000,
                merchantId: localStorage.getItem('merchantId'),
                pageEnum: 'EDIT_PAGE',
            },
            'get',
            {}
            )
            .then(res => {
            if (res.code == 0) {
                for(var i = 0; i < res.data.list.length; i++){
                    if(res.data.list[i].id == this.$route.query.id){
                        this.productList = res.data.list[i];
                        if(res.data.list[i].statusName.indexOf('审核未通过') != -1){
                            this.flags = true;
                        }else{
                            this.flags = false;
                        }
                    }
                }
            } else {
                Notify({ type: 'danger', message: res.message })
            }
                var timer = setTimeout(
                    function () {
                    this.loading = false
                    window.clearTimeout(timer)
                    }.bind(this),
                    3000
                )
            })
        }
    }
}
</script>
<style scoped>

</style>